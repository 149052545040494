<template>
  <div class="form-container">
    <form @submit.prevent="submitForm" class="form">
      <div class="form-group">
        <label for="orgUrl" class="form-label">Link gốc:</label>
        <input
          v-model="orgUrl"
          type="text"
          id="orgUrl"
          class="form-input"
          :class="{ 'input-error': errors.orgUrl }"
          placeholder="Nhập vào link URL"
          autocomplete="off"
        />
        <p v-if="errors.orgUrl" class="error-text">{{ errors.orgUrl }}</p>
      </div>
      <div class="form-group">
        <label for="shortlink" class="form-label">Mã ngắn:</label>
        <input
          v-model="shortlink"
          type="text"
          id="shortlink"
          class="form-input"
          :class="{ 'input-error': errors.shortlink }"
          placeholder="Nhập mã ngắn"
          autocomplete="off"
        />
        <p v-if="errors.shortlink" class="error-text">{{ errors.shortlink }}</p>
      </div>
      <button type="submit" class="submit-button" :disabled="loading">
        <span v-if="loading" class="loading-spinner"></span>
        Lưu mã ngắn
      </button>
    </form>
    <div v-if="result" class="result-message">
      <p v-if="result.success" class="success-message">
        Success: Link ngắn của bạn là <span> </span>
        <a :href="`https://link.hkoil.org/${result.shortlink}`" target="_blank">{{ `https://link.hkoil.org/${result.shortlink}` }}</a>
        <button @click="copyLink(`https://link.hkoil.org/${result.shortlink}`)" class="copy-button">Copy Link</button>
      </p>
      <p v-if="!result.success" class="error-message">Error: {{ result.error }}</p>
    </div>
    <router-link to="/" class="back-home-button">Quay lại trang chủ</router-link>
  </div>
</template>

<script>
export default {
  name: 'FormPage',
  data() {
    return {
      orgUrl: '',
      shortlink: '',
      result: null,
      errors: {
        orgUrl: '',
        shortlink: '',
      },
      loading: false,
    };
  },
  methods: {
    async submitForm() {
      // Clear previous errors
      this.errors = {
        orgUrl: '',
        shortlink: '',
      };

      let hasError = false;

      // Validate orgUrl for correct URL structure
      const urlPattern = /^https?:\/\/[^\s/$.?#].[^\s]*$/i;
      if (!this.orgUrl) {
        this.errors.orgUrl = 'Link gốc không được để trống.';
        hasError = true;
      } else if (!urlPattern.test(this.orgUrl)) {
        this.errors.orgUrl = 'Link gốc phải có định dạng http hoặc https.';
        hasError = true;
      }

      // Validate shortlink
      const noAccentPattern = /^[\w-]+$/; // Chỉ cho phép chữ cái, số, gạch ngang và gạch dưới
      const noSpacePattern = /^\S+$/; // Không cho phép khoảng trắng
      if (!this.shortlink) {
        this.errors.shortlink = 'Mã ngắn không được để trống.';
        hasError = true;
      } else if (!noAccentPattern.test(this.shortlink)) {
        this.errors.shortlink = 'Mã ngắn chỉ được chứa chữ cái, số, gạch ngang và gạch dưới (không dấu).';
        hasError = true;
      } else if (!noSpacePattern.test(this.shortlink)) {
        this.errors.shortlink = 'Mã ngắn không được chứa khoảng trắng.';
        hasError = true;
      } else if (this.shortlink.toLowerCase() === 'form') {
        this.errors.shortlink = 'Mã ngắn không được đặt tên là "form".';
        hasError = true;
      }

      if (hasError) {
        return;
      }

      this.loading = true;

      try {
        const response = await fetch('https://zchatbot-yzdc.onrender.com/slink', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orgUrl: this.orgUrl,
            shortlink: this.shortlink,
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        this.result = data;

        // Clear input fields on success
        this.orgUrl = '';
        this.shortlink = '';
      } catch (error) {
        console.error('Error:', error);
        this.result = { success: false, error: 'Failed to submit data.' };
      } finally {
        this.loading = false;
      }
    },
    copyLink(link) {
      navigator.clipboard.writeText(link).then(() => {
        alert('Link đã được sao chép vào clipboard!');
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }
  },
};
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 720px;
  margin: auto;
}

.form {
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  font-size: 1em;
  margin-bottom: 5px;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 1em;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
  outline: none;
}

.input-error {
  border-color: #dc3545;
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.2);
}

.error-text {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 5px;
}

.submit-button {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s;
  width: 100%;
  position: relative; /* Thêm position relative để chứa spinner */
}

.submit-button:disabled {
  background: #007bff;
  cursor: not-allowed;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 24px;
  height: 24px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.result-message {
  margin-top: 20px;
  text-align: center;
}

.success-message {
  color: #28a745;
  font-size: 1.1em;
  font-weight: bold;
  padding: 15px;
  border-radius: 8px;
  background: #d4edda;
  border: 1px solid #c3e6cb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.success-message a {
  color: #155724;
  text-decoration: none;
  margin-right: 10px;
}

.success-message a:hover {
  text-decoration: underline;
}

.copy-button {
  background: #17a2b8;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 0.9em;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s;
}

.copy-button:hover {
  background: #117a8b;
}

.error-message {
  color: #dc3545;
  font-size: 1.1em;
  font-weight: bold;
  padding: 15px;
  border-radius: 8px;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
}

.back-home-button {
  display: block;
  margin-top: 20px;
  text-align: center;
  font-size: 1em;
  color: #007bff;
  text-decoration: none;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 10px 20px;
  transition: background 0.3s, color 0.3s;
}

.back-home-button:hover {
  background: #9b9ea1;
  color: white;
}
</style>
