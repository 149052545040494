<template>
  <div v-if="loading" class="loading-container">
    <div class="loading">
      <div class="spinner"></div>
      <div class="loading-text">Loading...</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RedirectPage',
  data() {
    return {
      loading: true, // Bắt đầu với trạng thái loading
    };
  },
  async created() {
    const shortlink = this.$route.params.shortlink;
    const scriptUrl = `https://script.google.com/macros/s/AKfycbznGYnYu0WbZSV_f8opHDwYhTsNfhcP2ONZfCtDoPJ6-twcGQHvTNuHEwg2SHGIEhPgkw/exec?shortlink=${shortlink}`;
    
    try {
      const response = await axios.get(scriptUrl);
      const { orgUrl } = response.data;
      if (orgUrl) {
        window.location.href = orgUrl;
      } else {
        console.error('No orgUrl returned');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      this.loading = false; // Kết thúc trạng thái loading sau khi có phản hồi
    }
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Chiếm toàn bộ chiều cao của cửa sổ */
  background: rgba(255, 255, 255, 0.9); /* Nền sáng với độ trong suốt */
}

.loading {
  text-align: center;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1); /* Màu nền của spinner */
  border-radius: 50%;
  border-top: 8px solid #007bff; /* Màu sắc của spinner */
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite; /* Hiệu ứng quay */
  margin-bottom: 15px; /* Khoảng cách giữa spinner và text */
}

.loading-text {
  font-size: 1.2em;
  color: #007bff; /* Màu xanh dương */
  font-weight: bold;
  animation: fadeIn 1.5s ease-in-out infinite; /* Hiệu ứng nhấp nháy */
}

/* Hiệu ứng quay cho spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Hiệu ứng nhấp nháy cho text */
@keyframes fadeIn {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
</style>
