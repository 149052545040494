import { createRouter, createWebHistory } from 'vue-router';
import RedirectPage from '../components/RedirectPage.vue';
import FormPage from '../components/FormPage.vue';
import HomePage from '../components/Home.vue'; // Sử dụng tên component mới

const routes = [
  {
    path: '/:shortlink',
    name: 'RedirectPage',
    component: RedirectPage,
  },
  {
    path: '/form',
    name: 'FormPage',
    component: FormPage,
  },
  {
    path: '/',
    name: 'HomePage', // Sử dụng tên mới ở đây
    component: HomePage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
