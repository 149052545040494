<template>
  <div class="home-container">
    <!-- Logo Vue ở đầu trang, chính giữa -->
    <img src="../assets/logo.png" alt="Vue Logo" class="vue-logo">

    <!-- Tiêu đề được làm nổi bật -->
    <header class="header">
      <h1>Chào mừng bạn đến với hệ thống HKOIL ShortLink</h1>
    </header>

    <!-- Nội dung chính -->
    <main class="main-content">
      <p class="intro-text">Hệ thống tạo link ngắn của HKOIL giúp bạn dễ dàng quản lý và chia sẻ.</p>
      <router-link to="/form">
        <button class="go-to-form-button">Tạo link ngắn</button>
      </router-link>
    </main>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
/* Toàn bộ trang */
.home-container {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: linear-gradient(135deg, #f0f4f7, #ffffff);
  min-height: 100vh;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Hiệu ứng shadow */
  border-radius: 15px; /* Bo viền cho cả trang */
  border: 1px solid #e0e0e0;
}

/* Logo Vue ở chính giữa */
.vue-logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  background: white;
  padding: 5px;
}

/* Header nổi bật */
.header {
  background: linear-gradient(45deg, #2fd93d, #00c6ff);
  color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  text-align: center;
  animation: fadeIn 1.2s ease-in-out; /* Hiệu ứng sinh động */
}

.header h1 {
  margin: 0;
  font-size: 2.8em;
  font-weight: bold;
  text-transform: uppercase;
}

/* Nội dung chính */
.main-content {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;
}

.intro-text {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 30px;
}

.go-to-form-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 14px 32px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.go-to-form-button:hover {
  background-color: #155ab6;
  transform: translateY(-2px);
}

.go-to-form-button:active {
  background-color: #0d47a1;
  transform: translateY(0);
}

/* Hiệu ứng cho header */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
